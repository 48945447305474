.gt-btn {
    font-family: "Poppins";
    border-radius: 4px;
    &:not(&-xs) {
        // font-size: em(13);
        font-size: 13px;
    }
    &:focus,
    &:hover {
        border-color: transparent;
    }
    &-secondary {
        background-color: $bdr-clr-2;
        // border-color: $bdr-clr-2;
        box-shadow: none !important;
        color: $white !important;
        height: 27.5px;
        border: none;
        &:hover,
        &:active,
        &:active:focus-visible {
            background-color: $bdr-clr-2 !important;
            border-color: transparent !important;
            
        }
    }
    &-secondary-inverse {
        background-color: transparent;
        border-color: $bdr-clr-2;
        color: $bdr-clr-2 !important;
        &:focus,
        &:hover {
            background-color: transparent;
            border-color: $bdr-clr-2;
        }
    }
    &-primary-inverse {
        background-color: transparent;
        border-color: $white;
        &:focus,
        &:hover {
            background-color: transparent;
            border-color: $white;
        }
    }
    &-grad-primary {
        border-width: 0;
        background: $btn-table-bg;
        color: $white !important;
        &:hover,
        &:active,
        &:active:focus-visible {
            color: $white !important;
            background: $btn-table-bg !important;
        }
    }
    &-grad-outline-type {
        background: transparent;
        color: $btn-table-bg !important;
        border: 1px solid $btn-table-bg;
        padding: 5px;
        &:hover,
        &:active,
        &:focus,
        &:active:focus-visible {
            // color: $white !important;
            background: transparent !important;
            border: 1px solid $btn-table-bg;
        }
    }
    &-light-outline-type {
        background: transparent;
        color: $btn-table-lg !important;
        border: 1px solid $btn-table-lg;
        padding: 5px;
        &:hover,
        &:active,
        &:focus,
        &:active:focus-visible {
            // color: $white !important;
            background: transparent !important;
            border: 1px solid $btn-table-lg;
        }
    }
    &-xs {
        min-width: em(50);
        font-size: em(11);
        font-weight: 600;
        line-height: 1;
    }
    &-sm {
        min-width: em(110);
    }
    &-md {
        min-width: em(133);
    }
    &-lg {
        min-width: em(171);
    }
    &-xl {
        min-width: em(285);
    }
    &-tableColumnMT {
        margin-top: 10px ;
    }
}

// .bg-custom-primary,
// .bg-custom-primary:hover,
// .bg-custom-primary:active,
// .bg-custom-primary:focus-visible,
// .bg-custom-primary:active:focus-visible {
//     background-color: #0B4D89 ;
//   }
  .table-btn-primary {
    line-height: 1;
  }
 

@media screen and (max-width: 576px) {
    .gt-btn {
        &:only-of-type {
            min-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
