.card-container {
  margin-left: 2%;
  margin-right: 4%;
  margin-bottom: 3%;
  padding-top: 40px;
  padding-bottom: 20px;
  min-width: 700px;
}

.setting-icon {
  padding: 10px;
}

.label-Container {
  font-size: 13px !important;
  text-align: right;
}
.text-input {
  display: flex;
  justify-content: center;
}

.table-bottom-btn {
  height: 34px;
  width: 237px;
}

.card-container {
  .user-validation-handler {
    margin-bottom: 0 !important;
  }
}

.loader-handle {
  padding-top: 100px;
  padding-bottom: 50px;
  padding-left: 65%;
}

.input-validation-container {
  margin-bottom: 10px;
}
.settings-header-con{
  .settings-header {
    padding-left: 12px !important;
      padding-right: 12px !important;
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media screen and (max-width: 622px) {
  .label-Container {
    text-align: left !important;
  }
  .table-bottom-btn {
    min-width: 0px !important;
  }
  .loader-handle {
    padding-top: 50px;
    padding-left: 0;
  }

  .card-container {
    height: 300px;
    padding-left: 20px;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 623px) and (max-width: 767.98px) {
  .table-bottom-btn {
    min-width: 0px !important;
  }
  .label-Container {
    text-align: right !important;
  }
  .table-bottom-btn {
    min-width: 0px !important;
  }
  .loader-handle {
    padding-left: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 1071px) and (max-width: 1199.98px) {
  .card-container {
    padding-right: 30%;
    padding-left: 1%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .card-container {
    padding-right: 40%;
    padding-left: 1%;
  }
}

@media (min-width: 623px) and (max-width: 1071px) {
  .loader-handle {
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 0%;
  }
}

@media screen and (max-width: 575px) {
  .button-container-width {
    width: 100%;
  }
  .table-bottom-btn {
    height: 34px;
    width: 90%;
  }
}
