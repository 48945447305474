.inventory-column-btns {
    width: fit-content !important;
    cursor: not-allowed !important;
}
.add-req-inv-con {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}
.add-req-inv-table {
    table-layout: fixed;
    text-align: center;
}
.add-req-inv-con table tbody tr td:first-child,
.add-req-inv-con table thead tr th:first-child {
    padding-left: 0rem;
}
.add-new-req__control {
    height: 30px;
    min-height: 30px !important;
}
.add-new-req__container {
    width: 70%;
}

.add-new-req__placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ced4da;
}
.add-new-req__indicator,
.status-header-search__indicator {
    color: #74788d !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.add-new-req__indicators,
.status-header-search__indicators {
    min-width: inherit;
}
.add-new-req__indicator-separator,
.status-header-search__indicator-separator {
    display: none;
}

.add-new-req-select-con {
    width: 250px;
    margin-right: auto;
    margin-left: auto;
}

.view-detail-modal-delete {
    cursor: pointer;
}

.inventory-description {
    background: #f5f5f5;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #cdcdcd;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inventory-table {
    min-width: 1000px;
}
.add-new-request-table {
    min-width: 550px;
}

.inventory-catalog-modal {
    // width: 100% !important;
}

.inv-btn-alignment {
    padding-top: 0.42rem !important;
    padding-bottom: 0.42rem !important;
}
@media (min-width: 1400px) {
    .add-new-req__value-container {
        padding: 0px 8px !important;
    }
}
