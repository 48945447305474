.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  padding-left: 0px;
  width: 100%;
  white-space: normal;
  margin-right: 0px;
  font-size: 11px !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  padding: 2px;
}
.vertical-collpsed .vertical-menu {
  width: 85px !important;
}
.vertical-collpsed #page-topbar {
  left: 85px;
}
.vertical-collpsed .navbar-brand-box {
  width: 85px !important;
}
.gt-sidebar.vertical-menu .navbar-brand-box .logo-light {
  padding-top: 5px;
}
.logo-sm-size,
.logo-lg-size {
  font-size: 16px;
}
.vertical-collpsed .main-content {
  margin-left: 85px;
}
@media (max-width: 992px){
.logo span.logo-lg {
    display: inline-block;
}
.logo span.logo-sm {
  display: none;
}
#page-topbar {
  left: 110px;
}
.logo-lg-size {
  font-size: 12px;
}
.vertical-menu,
.gt-sidebar.vertical-menu .navbar-brand-box{
  width: 110px;
}
.main-content {
  margin-left: 110px;
}
.navbar-brand-box .logo-dark span.logo-sm{
  display: none;
}}
