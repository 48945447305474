.pdfdownload-gray {
    filter: saturate(10%) brightness(200%) grayscale(100%);
    -webkit-filter: saturate(10%) brightness(200%) grayscale(100%);
}
.pdfdownload-icon {
    cursor: pointer;
}
.detail-view-table {
    min-width: 1200px;
}

.fm-bg-light-green {
    background-color: #34c38f80;
    color: #004a2f;
}
.fm-bg-light-yellow {
    background-color: #f1b44c80;
    color: #754f0d;
}
.fm-bg-light-red {
    background-color: #f46a6a80;
    color: #950000;
}

.image-upload-container {
    // border: 1px solid #ced4da;
    // height: 200px;
}

.imageHandle {
    // object-fit: contain;
    // height: 100%;
    // width: 100%;
}
.image-preview-container {
    // padding: 10px;
    // width: 200px;
    // height: 200px;
}

.image-preview {
    // width: 100%;
    // height: 100%;
    // object-fit: contain;
}

.uploaded-file-box {
    padding: 5px;
    border: 1px solid #ced4da;
    width: fit-content;
    border-radius: 4px;
}
.media-item {
    width: 150px;
    height: 120px;
}
.media-item {
}
.media-icon {
    width: 50px;
    height: 50px;
}

.media-icon-box {
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.media-container {
    max-height: 500px;
}
.media-remove-btn-container {
    text-align: right;
}

.media-remove-btn {
}
.receiving-form-label {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: black !important;
}
.receiving-form-table > tr * {
    margin-top: 3px;
    margin-bottom: 3px;
}
