.updated-selected-item-listing-table-classes {
    min-width: 460px;
}
.stock-status {
    .stock-status-label {
        width: 15.66666%;
    }
}
@media (max-width: 1200px) {
    .stock-status {
        .stock-status-label {
            width: 16.66666%;
        }
    }
}
@media (max-width: 992px) {
    .stock-status {
        .stock-status-label {
            width: 17%;
        }
    }
    .stock-status {
        .stock-status-data {
            width: 83%;
        }
    }
}
@media (max-width: 850px) {
    //   .wh-inventory-management > .nav-tabs .nav-link.active,
    // .wh-inventory-management > .nav-tabs .nav-item .nav-link,
    .warehouse-nav-tabs > .nav-tabs .nav-item .nav-link,
    .warehouse-nav-tabs > .nav-tabs .nav-link.active,
    .im-header-btns {
        padding: 5px 10px;
    }
}

@media (max-width: 768px) {
    .warehouse-nav-tabs > .nav-tabs .nav-item .nav-link {
        width: 140px;
    }

    .wh-inv-comp-header-btns {
        padding: 5px;
    }
    .stock-status {
        .stock-status-label {
            padding-left: 2rem !important;
        }
    }
}
@media (max-width: 700px) {
    .stock-status {
        .stock-status-label {
            padding-left: 1rem !important;
        }
    }
}
@media (max-width: 650px) {
    .wh-im-table-heading {
        width: 105px;
    }
    .im-header-btns {
        min-width: inherit !important;
    }
}
