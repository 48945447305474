.top-heading-con{
  .top-heading-typo{
    margin-bottom: 0px !important;
  }
}
@media (max-width: 800px) {
  .order-management-con{
    .order-man-top-heading {
      flex-direction: column;
      align-items: start !important;
    }
  }
  .order-man-select {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

