.image-container{
    height: 103px;
    width: 101px;
  
 

}
.__image{
    width: 100%;
    height: 100%;
}

.link-card{

    height: 241px !important;
    width: 241px !important;
    border-radius: 30px !important;
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
    box-shadow: rgba(0, 0, 0, 0.1) !important;
    
    
}

.__text{
    color: black;
}


