.gt-search-box {
    width: em(227);
    display: inline-block;
}

.gt-search-box-input {
    border: 1px solid #ced4da !important;
    border-radius: 4px;
}

.table-main-search-input {
    width: 180px;
    padding: 0.75px 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    min-height: 33px;
}
.table-main-search-label {
    color: #767c81;
}
