// Tools
@import "casita-variables";
@import "mixins";
@import "functions";
@import "typography";
@import "utils";
// Components
@import "components/auth-flow";
@import "components/button";
@import "components/loader";
@import "components/searchBox";
@import "components/pagination";
@import "components/page-limit";
@import "components/badge";
@import "components/dataTable";
@import "components/topbar";
@import "components/sidebar";
@import "components/form-check";
@import "components/form-control";
@import "components/codeinput";
@import "components/modal";
@import "components/labels";
@import "components/imagePlaceholder";
@import "components/avatar";
@import "components/breadcrumb";
@import "components/card";
@import "components/product";
@import "components/toggle-btn";
@import "components/text-input";
@import "components/productCartItem";
@import "components/ImageCardHolder";
@import "components/colorPalette";
@import "components/productSelectDropdown";
@import "components/quantityCounter";
@import "components/productSimple";
@import "components/productSizeVariation";
@import "components/ProductSimpleTwoCol";
@import "components/menuNotificationDropdown";
// Pages
@import "pages/dashboard";
@import "pages/categoryListing";
@import "pages/businessAccount";
@import "pages/reportDetails";
@import "pages/forgetVerifyCode";
@import "pages/inventoryRequest";
@import "pages/signin";

// body-css
body {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: $black;
    // background:  $body-bg;

    &.modal-open{
        .modal{
            &.show{
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px); 
            }
        }
    }
}

$spacing: (
    (ml, margin-left),
    (mt, margin-top),
    (mb, margin-bottom),
    (mr, margin-right),
    (pl, padding-left),
    (pt, padding-top),
    (pb, padding-bottom),
    (pr, padding-right)
);

@each $item in $spacing {
    @include generateSpacing(nth($item, 1), nth($item, 2));
}

@include generateUtils("gtw", "width");
@include generateUtils("gtw-max", "max-width");

@include generateUtils("gth", "height");
@include generateUtils("gth-max", "max-height");

@include generateUtils("fs", "font-size");

@include generateRadius("radius");

@media screen and (max-width: 1400px) {
    body {
        font-size: 14px;
    }
}

@media screen and (max-width: 1199px) {
    body {
        font-size: 12px;
    }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
    .ml-mob-0 {
        margin-left: 0;
    }
    .mr-mob-0 {
        margin-right: 0;
    }
}
