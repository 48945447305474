.gt-toggle-switch-btn {
  label {
    &:before {
      background-color: $white;
    }
  }
  input[type="checkbox"] {
    &.switched + label {
      background-color: $primary;
    }
  }
  input[type="checkbox"] {
    visibility: hidden;
    &.switched + label {
      &:before {
        transform: translateX(18px);
      }
    }
  }
  input[type="checkbox"] {
    &:not(.switched) + label {
      background-color: $primary;
    }
  }
  label {
    display: flex;
    width: 44px;
    height: 26px;
    @include radius(32px);
    position: relative;
    transition: transform 0.55s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;
    &:before {
      transition: transform 0.75s ease;
      transition-delay: 0.5s;
      content: "";
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      @include radius(50%);
      top: 4px;
      left: 4px;
      @include shadow(0px 1px 1px rgba($color: $black, $alpha: 0.24));
    }
  }
}
