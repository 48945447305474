.error-msg-below-input{
  margin-left: 10px;
}
.error-message-aligning{
  width: 75%;
    // text-align: right;
    margin-left: 0px;
}
.create-order-lastitem{
  width: 33.33%;
}
// @media (max-width : 1400px) {
//   .error-msg-below-input{
//   text-align: left;
//   margin-left: 3.1rem;
//   }
// }
// @media (max-width : 1200px) {
//   .error-msg-below-input{
//   margin-left: 1rem;
//   }
// }
@media (max-width : 767px) {
  .error-msg-below-input{
    width: 83%;
  }
}
// @media (max-width : 700px) {
//   .error-msg-below-input{
//   margin-left: 1rem;
//   }
// }