.analytics-box-container {
    // width: 342px;
    // width: 304px;
    height: 157px;
    // margin-left: 5px;
    // margin-right: 5px;
    border-radius: 10px !important;
}

.analytics-box-data-handler {
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
}

.analytics-box-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.analytics-box-title {
    position: absolute;
    width: 163px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.analytics-box-data {
    font-weight: 700;
    font-size: 50px;
    line-height: 20px;
    color: #0b4d89;
}
.icon-img-container {
    width: 47px;
    height: 47px;
    background: rgba(11, 77, 137, 0.2);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    display: inline-block;
}
.analytics-box-category-title {
    padding-top: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    /* or 129% */
    color: #000000;
}
.analytics-box-category-description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 2px;
}
.analytics-box-bottom-btn {
    height: 35px;
    width: 150px;
}
.analytics-box-icon-img {
    padding: 10px !important;
}

@media only screen and (max-width: 992px) {
    .analytics-box-title {
        width: 100px;
    }
}
