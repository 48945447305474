
.gt-text-input-wrap{
.gt-text-input-field{
    &.gt-bg-clr-gray-light-6{
        background-color: $gray-light-6;
    }
    &.gt-with-icon-text{
        padding-right: em(45);
    }
    &.gt-date-icon{
        // background: url("../../../../assets/images/gyft-icons/eye-icon.png") no-repeat center 90%;
    }
}
.gt-text-icon-wrapper{
    z-index: 99;
    .bg-settings-tick-icon{
        background: linear-gradient(144deg, #f65d4f 0%, #b14ea6 33.33%, #7a65d5 66.66%, #3bafe5 100%);
    }
}
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    // display: none;
    // -webkit-appearance: none;
    opacity: 0;
}

.gt-date-picker-icon-wrapper {
    bottom: 25%;
    margin-right: em(15);
}

.gt-date-picker-input {
    font-size: em(13);
}

.remove-validation-icons{
    .form-control.is-invalid{
        
        background-image: none;
    }
    .form-control.is-valid{
        background-image: none;
    }
}


.file-input-wrapper{
input[type="file"]::file-selector-button{
        background-color: #D5D5D5;
        color: black;
  }
}

.file-input-wrapper{
  input[type="file"]::-ms-browse{
    background-color: #D5D5D5;
    color: black;
  }
}

.file-input-wrapper{
  input[type="file"]::-webkit-file-upload-button {
    background-color: #D5D5D5;
    color: black;
  }
}

.form-input-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
    text-align: start;
}


.hide-num-arrows{
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
}
}
.hide-num-arrows{
    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
}