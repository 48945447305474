.wh-inventory-management > .nav-tabs .nav-link.active,
.wh-inventory-management > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs > .nav-tabs .nav-link.active {
    border-radius: 5px;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 5px 30px;
    border: none;
    justify-content: center;
    // text-transform: capitalize;
}
.wh-inventory-management > .nav-tabs > .nav-item .nav-link {
    background: #bfbfbf;
}
.wh-inventory-management > .nav-tabs .nav-link.active,
.wh-inventory-management > .nav-tabs .nav-item.show .nav-link {
    background: #0b4d89;
}
.wh-inventory-management > .nav-tabs > .nav-item .nav-link:hover,
.wh-inventory-management > .nav-tabs > .nav-item .nav-link:focus {
    border: none;
    isolation: inherit;
}
.warehouse-nav-tabs > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs > .nav-tabs .nav-link.active {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    z-index: 100;
}
.warehouse-nav-tabs > .nav-tabs .nav-item .nav-link {
    background: #ced4da;
    opacity: 0.5;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    color: #4e4e4e;
    min-width: 180px;
}
.warehouse-nav-tabs > .nav-tabs .nav-link.active {
    color: #0b4d89;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    margin-right: 2px;
    margin-left: 2px;
}
.warehouse-nav-tabs > .nav-tabs .nav-link.active {
    margin-right: 0px;
    margin-left: 0px;
}
.warehouse-nav-tabs > .nav-tabs .nav-link:first-child {
    margin-right: 2px;
}
.warehouse-nav-tabs > .nav-tabs .nav-link.active:last-child {
    margin-right: 2px;
}

.warehouse-nav-tabs > .nav-tabs .nav-link.active::after,
.warehouse-nav-tabs > .nav-tabs .nav-link:hover.active::after {
    content: "";
    height: 4px;
    width: 80%;
    position: absolute;
    left: 10%;
    top: 100%;
    background: #0b4d89;
}

.wh-im-table-heading {
    font-size: 18px;
}

.wh-im-wh-gi-con > .gt-data-table table thead th,
.wh-im-wh-gi-con > .gt-data-table table tbody td,
.wh-im-wh-ch-con > .gt-data-table table tbody td,
.wh-im-wh-ch-con > .gt-data-table table thead th {
    // text-align: center;
}
.card-body {
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 4px;
}
.bg-green-light {
    // background: rgba(52, 195, 143, 0.5);
    background: #4cbb17;
    color: white;
}
.bg-red-light {
    // background: rgba(244, 106, 106, 0.5);
    background: #ff2400;
    color: white;
}
.bg-orange-light {
    // background: rgba(241, 180, 76, 0.5);
    background: #edd213;
    color: white;
}
.wh-im-global-quantity {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;
    width: 110px;
    // margin: auto;
}
.wh-im-wi-ch-btn {
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
    padding: 1px 5px !important;
}
.wh-im-dpgi-status {
    width: 200px;
}
.wh-im-dpgi-addnewitem {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    color: #9d9d9d;
}
.btn-height {
    height: 30px;
}
.ws-nowrap {
    white-space: nowrap;
}
.color-box {
    width: 28px;
    height: 28px;
}
.bg-medium-red {
    background: #ff2400;
}
.bg-medium-orange {
    background: #edd213;
}
.bg-medium-green {
    background: #4cbb17;
}
.addnewitem-modal-color-input {
    border: 1px solid #d4d9df;
    border-radius: 4px;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
}

.addnewitem-typo {
    font-size: 13px;
    line-height: 15px;
    color: #000000;
}
.min-width-70 {
    min-width: 70px;
}

.add-new-item__control {
    height: 34.5px;
    min-height: 34.5px !important;
}
.add-new-item__container {
    width: 70%;
}

.add-new-item__placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ced4da;
}
.add-new-item__indicator {
    color: #74788d !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.add-new-item__indicators {
    min-width: inherit;
}
.add-new-item__indicator-separator {
    display: none;
}

.wh-im-item-table {
    min-width: 490px;
}
.wh-im-table {
    min-width: 675px;
}

.wh-chemicals-table {
    min-width: 600px;
}

.wh-tools-table {
    min-width: 700px;
}

.dp-gi-table {
    min-width: 805px;
}

.dp-tools-table {
    min-width: 860px;
}

.wh-im-vd-table {
    min-width: 640px;
}
.add-req-inv-table {
    min-width: 900px;
}
.create-order-im {
    min-width: 550px;
}
.textarea-label {
    width: 15.4% !important;
}
.textarea-ip-con {
    width: 83% !important;
}
.add-inv-multi > button {
    min-width: 75%;
    margin-bottom: 0px;
}
.inv-sample-download {
    text-align: center;
}
.inv-sample-download > img {
    width: 24px;
    height: 24px;
}
.inv-sample-download {
    a,
    a:hover {
        text-decoration: underline !important;
        color: #0080ff;
        font-size: 15px;
    }
}
.item-listing-action {
    min-width: 130px;
}

.inv-modal {
    max-height: calc(100vh - 300px); /* Adjust as needed */
    overflow-y: auto;
}

@media (min-width: 1400px) {
    .add-new-item__value-container {
        padding: 0px 8px !important;
    }
}

.wh-im-vd-con {
    width: 220px !important;
}
.wh-im-header-typo {
    font-size: 14px;
    color: #74788d;
}
.wh-im-vd-con > div > .add-new-req__control {
    height: 40px;
    min-height: 40px !important;
}
.table-body-border table tbody tr td {
    border-right: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
}
.table-body-border table tbody tr td:last-child {
    border-right: none;
}
.table-body-border .w-20 {
    width: 20%;
}
.table-body-border .w-80 {
    width: 80%;
}
.responsive-table-whgi table tbody tr td:last-child {
    min-width: 110px;
}
.responsive-table-whch table tbody tr td:last-child {
    padding-left: 5px;
}
.responsive-table-whch table tbody tr td:nth-last-child(2) {
    min-width: 110px;
}
.btn-alignment {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
}
.textarea-max-width {
    max-width: 100%;
}

.im-header-btns {
    padding-right: 24px;
    padding-left: 24px;
}
.category-btn-con {
    .btn-alignment-category {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
}
.inv-file-upload-con,
.inv-file-uploaded-con {
    border: 1px solid #0b4d89;
    width: 75%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.inv-file-uploaded-con {
    color: #fff !important;
    background: #0b4d89 !important;
}
.upload-csv-btn {
    width: 75%;
}
.csv-upload-type {
    word-break: break-all;
    white-space: normal;
}
.download-img-icon {
    width: 16px !important;
    height: 15px !important;
    margin-right: 5px;
}
/* TABLE INNER BUTTONS RIGHT SIDE OF HEADING */
@media (max-width: 1400px) {
    .wh-im-search-input {
        padding: 4px 5px !important;
    }
}
@media (max-width: 992px) {
    .wh-im-wh-ch-con > .gt-data-table table tbody tr td:first-child,
    .wh-im-wh-ch-con > .gt-data-table table thead tr th:first-child {
        padding-left: 0px !important;
    }
    .btn-alignment {
        padding-top: 0.35rem !important;
        padding-bottom: 0.2rem !important;
    }
    .category-btn-con {
        .btn-alignment-category {
            padding-top: 0.6rem;
            padding-bottom: 0.4rem;
        }
    }
}
@media (max-width: 1198px) {
    .textarea-label {
        width: 15.8% !important;
    }
    .textarea-ip-con {
        width: 82.4% !important;
    }
}
@media (max-width: 768px) {
    .wh-inventory-management > .nav-tabs .nav-link.active,
    .wh-inventory-management > .nav-tabs .nav-item .nav-link {
        padding: 5px 10px;
    }
    .wh-im-header-inner-btns {
        min-width: inherit !important;
    }
    .stock-status-input {
        width: 150px !important;
    }
    .textarea-label {
        width: 17.5% !important;
    }
    .textarea-ip-con {
        width: 72% !important;
    }
}
