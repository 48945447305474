.gt-sidebar {
  display: flex;
  flex-direction: column;
  &.vertical-menu {
    // display: none;

    .navbar-brand-box {
      width: $sidebar-width;
      display: flex !important;
      justify-content: center !important;
      background-color: inherit;
      min-height: em(70);
      .logo-light {
        line-height: em(70);
        .logo-sm {
          img {
            max-width: em(136);
            max-height: em(38);
          }
        }
        .logo-lg {
          img {
            max-width: em(136);
            max-height: em(38);
          }
        }
      }
    }
    .gt-side-menu {
      margin-top: em(52);
      ul#side-menu {
        li {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: em(13);
            font-weight: 600;
            font-family: "Poppins";
            // color: $white;
            color: #578cbb;
            img {
              filter: brightness(0) saturate(100%) invert(54%) sepia(61%)
                saturate(332%) hue-rotate(166deg) brightness(85%) contrast(96%);
              // not needed in casita
              //  margin-inline-end: em(11.06);
            }
          }
          // not needed in casita
          // &:hover,
          &.mm-active {
            > {
              a {
                position: relative;
                &::before {
                  content: "";
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  z-index: -1;
                  background: $casita-blue;
                  mix-blend-mode: soft-light;
                  left: 0;
                  top: 0;
                  opacity: 0.23;
                  background: transparent;
                  // not needed in casita
                  // linear-gradient(90deg, $white 0%, $sidenav-grad-secondary 100%);
                }
                // added for casita only
                img {
                  // filter: brightness(0) saturate(100%) invert(14%) sepia(32%)
                  //   saturate(2957%) hue-rotate(186deg) brightness(92%)
                  //   contrast(101%);
                  filter: brightness(0) saturate(100%) invert(84%) sepia(93%)
                    saturate(0%) hue-rotate(169deg) brightness(114%)
                    contrast(104%);
                }

                span {
                  color: $white;
                  font-size: em(13);
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      top: em(70);
    }
  }
}
